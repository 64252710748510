import { template as template_bcf92d5589cb41aab785447d3e5c1818 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bcf92d5589cb41aab785447d3e5c1818(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
