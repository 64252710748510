import { template as template_ba65b4a96ce94b239f515372e6b0460a } from "@ember/template-compiler";
const FKText = template_ba65b4a96ce94b239f515372e6b0460a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
